<template>
  <Page>
    <Content class="page-content">
      <div class="button-container">
        <Button
          title="Ajouter un Oxilio"
          @click="openModalAddOxilio = true"
          type="secondary"
        />
      </div>
      <Table
        :header="header"
        :raws="devices"
        @editOxilio="edit"
        @remove="openRemoveModal"
      />
    </Content>
    <Modal
      v-if="openModalAddOxilio"
      @close="openModalAddOxilio = false"
      :isOxilioModal="true"
    >
      <div class="center-flex">
        <div :style="{ fontSize: '18px' }">Ajouter un Oxilio</div>
        <div class="center-flex padding">
          <div class="flex">
            <span>Identifiant</span>
            <Input v-model="oxilio.identifier" />
          </div>
          <div class="flex">
            <span>Taille</span>
            <Select
              :options="sizeTypes"
              :style="{ width: '200px' }"
              v-model="oxilio.size"
            />
          </div>
          <div class="flex">
            <span>IMEI</span>
            <Input v-model="oxilio.imei" />
          </div>
        </div>
        <Button
          title="Ajouter un Oxilio"
          type="secondary"
          :style="{ width: '200px' }"
          @click="addOxilio"
          :disabled="oxilio.identifier === '' || oxilio.size === '' || oxilio.imei === ''"
        />
      </div>
    </Modal>
    <Modal v-if="openModalEdit" @close="openModalEdit = false" :isOxilioModal="true">
      <div class="center-flex">
        <div :style="{ fontSize: '18px' }">Editer un Oxilio</div>
        <div class="center-flex padding-edit">
          <div :style="{ marginBottom: '30px' }">
            <div class="flex width-edit">
              <span class="old-text">Ancien identifiant</span>
              <span class="old-text">{{ deviceToEdit.identifier }}</span>
            </div>
            <div class="flex width-edit">
              <span>Nouvel identifiant</span>
              <Input v-model="oxilio.identifier" />
            </div>
          </div>

          <div :style="{ marginBottom: '30px' }">
            <div class="flex width-edit">
              <span class="old-text">Ancienne taile</span>
              <span class="old-text">{{ sizeLabel(deviceToEdit.size) }}</span>
            </div>
            <div class="flex width-edit">
              <span>Nouvelle taille</span>
              <Select
                :options="sizeTypes"
                :style="{ width: '200px' }"
                v-model="oxilio.size"
              />
            </div>
          </div>

          <div :style="{ marginBottom: '30px' }">
            <div class="flex width-edit">
              <span class="old-text">Ancien IMEI</span>
              <span class="old-text">{{ deviceToEdit.imei }}</span>
            </div>
            <div class="flex width-edit">
              <span>Nouvel IMEI</span>
              <Input v-model="oxilio.imei" />
            </div>
          </div>

            <div :style="{ marginBottom: '30px' }">
            <div class="flex width-edit">
              <span class="old-text">Ancien statut</span>
              <span class="old-text">{{ statusLabel(deviceToEdit.status) }}</span>
            </div>
            <div class="flex width-edit">
              <span>Nouveau statut</span>
              <Select
                :options="statusTypes"
                :style="{ width: '200px' }"
                v-model="oxilio.status"
              />
            </div>
          </div>
          <Button
            title="Modifier cet Oxilio"
            type="secondary"
            :style="{ width: '200px' }"
            @click="saveEditOxilio"
            :disabled="
              oxilio.identifier === '' || oxilio.size === '' || oxilio.imei === '' || oxilio.status === ''
            "
          />
        </div>
      </div>
    </Modal>
    <Modal v-if="showModalRemove" @close="showModalRemove = false" :isOxilioModal="true">
      <div class="center-flex">
        <span class="padding-remove">
          Souhaitez-vous vraiment démanteler cet Oxilio ? Il ne sera plus enregistré dans
          la base de donnée Lifebloom.
        </span>
        <Button
          title="Démanteler cet Oxilio"
          type="warning"
          :style="{ width: '200px' }"
          @click="remove"
        />
      </div>
    </Modal>
  </Page>
</template>

<script>
import Page from "@/components/Layout/Page"
import Content from "@/components/Layout/Content"
import Button from "@/components/Button"
import Table from "../../components/Table/Table.vue"
import { mapGetters } from "vuex"
import Modal from "../../components/ConfirmationModal/Modal.vue"
import Input from "../../components/Forms/Fields/Input.vue"
import Select from "../../components/Forms/Fields/Select.vue"

export default {
  name: "FlotteOxilio",

  data() {
    return {
      header: [
        { identifier: "Identifiant", index: 0 },
        { identifier: "Taille", index: 1 },
        { identifier: "Lieu", index: 2 },
        { identifier: "Statut", index: 3 },
        { identifier: "Batterie", index: 4 },
        { identifier: "IMEI", index: 5 },
        { identifier: "Editer", index: 6 },
        { identifier: "Démanteler", index: 7 }
      ],
      sizeTypes: [
        { value: 0, name: "Taille 1" },
        { value: 1, name: "Taille 2" }
      ],
      oxilio: {
        identifier: "",
        size: "",
        imei: "",
        id: ""
      },
      deviceToEdit: {},
      openModalEdit: false,
      openModalAddOxilio: false,
      showModalRemove: false,
      idDeviceToDelete: 0
    }
  },

  async created() {
    await this.$store.dispatch("admin/getDevices")
  },

  methods: {
    async remove() {
      await this.$store.dispatch("admin/removeDevice", this.idDeviceToDelete)
      this.showModalRemove = false
    },
    async saveEditOxilio() {
      await this.$store.dispatch(`admin/editDevice`, this.oxilio)
      this.openModalEdit = false
    },
    sizeLabel(size) {
      return size > 1 ? "" : this.sizeTypes[size].name
    },
    statusLabel(status) {
      return status > 8 ? "" : this.statusTypes[status].name
    },
    formatOptions(options) {
      return Object.entries(options).map((entry) => ({
        value: parseInt(entry[0]),
        name: entry[1],
      }));
    },
    openRemoveModal(idDevice) {
      this.showModalRemove = true
      this.idDeviceToDelete = idDevice
    },
    edit(data) {
      this.deviceToEdit = data
      this.oxilio.identifier = this.deviceToEdit.identifier
      this.oxilio.size = this.deviceToEdit.size
      this.oxilio.imei = this.deviceToEdit.imei
      this.oxilio.id = this.deviceToEdit.id
      this.oxilio.status = this.deviceToEdit.status
      this.openModalEdit = true
    },
    async addOxilio() {
      await this.$store.dispatch("admin/addDevices", this.oxilio)
      this.openModalAddOxilio = false
    }
  },
  components: {
    Page,
    Content,
    Button,
    Table,
    Modal,
    Input,
    Select
  },
  computed: {
    ...mapGetters({
      devices: "admin/devices"
    }),
    statusTypes() {
      return this.formatOptions(this.$t("statusType"))
    },
  }
}
</script>
<style lang="scss" scoped>
.page {
  background: #fff;
  height: 100vh;
}
.button-container {
  display: flex;
  align-items: center;
  margin: 60px 0 24px 0;
}
.center-flex {
  flex-direction: column;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.flex {
  display: flex;
  justify-content: space-between;
  width: 290px;
  align-items: center;
}
.width-edit {
  width: 370px;
}
.flex-no-space {
  display: flex;
  width: 370px;
  align-items: center;
}
.padding {
  padding: 119px 0;
}
.padding-edit {
  padding-top: 70px;
}
.old-text {
  margin-right: 10px;
  font-style: italic;
  color: #8e8e95;
}
.padding-remove {
  padding: 230px 0;
}
</style>
