<template>
  <tr>
    <td>{{ raw.identifier }}</td>
    <td>{{ sizeLabel(raw.size) }}</td>
    <td>{{ raw.location }}</td>
    <td>{{ statusLabel(raw.status) }}</td>
    <td>{{ raw.batteryPower }}</td>
    <td>{{ raw.imei }}</td>
    <td><Button @click="editOxilio(raw)" title="Editer" type="primary" /></td>
    <td>
      <Button
        title="Démanteler"
        :disabled="raw.isUsed"
        @click="remove(raw.id)"
        :type="!raw.isUsed ? 'warning' : 'not-allowed'"
      />
    </td>
  </tr>
</template>
<script>
import Button from "../Button.vue"
export default {
  props: ["raw"],
  data() {
    return {
      sizeTypes: ["Taille 1", "Taille 2"]
    }
  },
  created() {},
  methods: {
    sizeLabel(size) {
      return size > 1 ? "" : this.sizeTypes[size]
    },
    statusLabel(status) {
      return status > 8 ? "" : this.statusTypes[status].name
    },
    remove(id) {
      this.$emit("remove", id)
    },
    editOxilio(device) {
      this.$emit("editOxilio", device)
    },
    formatOptions(options) {
      return Object.entries(options).map((entry) => ({
        value: parseInt(entry[0]),
        name: entry[1],
      }));
    },
  },
   computed: {
    statusTypes() {
      return this.formatOptions(this.$t("statusType"))
    },
  },
  components: { Button }
}
</script>
<style lang="scss" scoped>
table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  th,
  td {
    padding: 8px 10px;
    vertical-align: middle;
  }

  th {
    padding: 10px;
    user-select: none;
  }

  .image {
    padding: 2px;
    width: 50px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 5px;
    }
  }

  .icon {
    text-align: center;

    svg {
      width: 20px;
      color: $main-color;
    }
  }

  .edit {
    a {
      display: block;

      svg {
        width: 20px;
        transition: all 0.25s;
      }

      &:hover {
        svg {
          fill: $main-color;
        }
      }
    }
  }
}
</style>
