<template>
    <thead>
        <th v-for="(header, $index) in header"
            :key="$index"
            :header="header">
            <td class="sortable">{{header.identifier}}</td>
        </th>
    </thead>
</template>
<script>
export default {
    props:['header']
}
</script>
<style lang="scss" scoped>
    table {
        border-collapse: collapse;
        border-radius: 10px;
        overflow: hidden;
      
        thead {
          font-size: 1.1rem;
          font-weight: 420;
          text-align: left;
          background: $main-color;
          color: $white-color;
      
          .sortable {
            cursor: pointer;
            transition: all 0.25s;
      
            &:hover {
              opacity: 0.75;
            }
          }
        }
      
        tbody {
          tr {
            &:nth-child(2n) {
              background: #f7f7f7;
            }
          }
        }
      
        th,
        td {
          padding: 8px 10px;
          vertical-align: middle;
        }
      
        th {
          padding: 10px;
          user-select: none;
        }
      
        .image {
          padding: 2px;
          width: 50px;
          height: 38px;
      
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 5px;
          }
        }
      
        .icon {
          text-align: center;
      
          svg {
            width: 20px;
            color: $main-color;
          }
        }
      
        .edit {
          a {
            display: block;
      
            svg {
              width: 20px;
              transition: all 0.25s;
            }
      
            &:hover {
              svg {
                fill: $main-color;
              }
            }
          }
        }
      }
</style>
